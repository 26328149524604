import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';

async function routesPDF(data, registers, from, to, devices, qtdDevices) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  /* TTITULO DO PDF */
  const reportTitle = [
    {
      text: 'Relatório de Rotas',
      fontSize: 12,
      bold: true,
      margin: [20, 20, 0, 2], /* LEFT, TOP, RIGHT, BOTTOM */
    },
    {
      text: `Período : ${moment(from).format('DD/MM/YYYY HH:mm:ss')} - ${moment(to).format('DD/MM/YYYY HH:mm:ss')}`,
      fontSize: 12,
      bold: true,
      margin: [20, 2, 0, 2], /* LEFT, TOP, RIGHT, BOTTOM */
    },
    {
      text: `Quantidade de dispositivos: ${qtdDevices} - Registros Encontrados: ${registers}`,
      fontSize: 12,
      bold: true,
      margin: [20, 2, 0, 20], /* LEFT, TOP, RIGHT, BOTTOM */
    },

  ];
  const dados = data.map((item) => [
    { text: devices[item?.deviceId]?.name, fontSize: 9, margin: [0, 2, 0, 2] },
    { text: moment(item?.fixTime).format('DD/MM/YYYY HH:mm:ss'), fontSize: 9, margin: [0, 2, 0, 2] },
    { text: item?.latitude, fontSize: 9, margin: [0, 2, 0, 2] },
    { text: item?.longitude, fontSize: 9, margin: [0, 2, 0, 2] },
    { text: `${item?.speed} Km/h`, fontSize: 9, margin: [0, 2, 0, 2] },
    { text: item?.address, fontSize: 9, margin: [0, 2, 0, 2] },
  ]);
  /* DETALHES DO PDF */
  const details = [
    {
      table: {
        headerRows: 1,
        /* widths: ['*', '*', '*', '*', '*', '*'], */
        widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        alignment: 'center',
        body: [
          [
            { text: 'Dispositivo', style: 'tableHeader', fontSize: 10 },
            { text: 'Data/Hora', style: 'tableHeader', fontSize: 10 },
            { text: 'Latitude', style: 'tableHeader', fontSize: 10 },
            { text: 'Longitude', style: 'tableHeader', fontSize: 10 },
            { text: 'Velocidade', style: 'tableHeader', fontSize: 10 },
            { text: 'Endereço', style: 'tableHeader', fontSize: 10 },
          ],
          ...dados,
        ],
      },
      layout: 'lightHorizontalLines', // headerLineOnly
    },
  ];

  /* RODAPE DO PDF */
  function Rodape(currentPage, pageCount) {
    return [
      {
        text: `${currentPage}/${pageCount}`,
        alignment: 'right',
        fontSize: 9,
        margin: [0, 10, 20, 0], /* LEFT, TOP, RIGHT, BOTTOM */
      },
    ];
  }

  const docDefinitions = {
    pageSize: 'A4',
    width: 595.28,
    pageOrientation: 'landscape',
    pageMargins: [20, 90, 10, 60], /* LEFT, TOP, RIGHT, BOTTOM */
    watermark: { text: 'SisalTrack', color: 'blue', opacity: 0.3, bold: true, italics: false }, /* Marca dágua */
    header: [reportTitle], /* cabeçalho */
    content: [details], /* conteudo */
    footer: Rodape, /* rodape */
  };
  const pdfName = 'Relatorio-de-Rotas.pdf';
  pdfMake.createPdf(docDefinitions).download(pdfName);
}
export default routesPDF;
