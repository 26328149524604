import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import removeFence from '../../resources/removeFence.mp3';

const RemoveAnchorGeofenceDialog = ({ open, close, onResult, anchorId, notificationId, commandId, device }) => {
  const navigate = useNavigate();
  const handleDeleteGetGeofence = async () => {
    /* REMOVE ANCORA */
    if (anchorId) {
      const response = await fetch(`/api/geofences/${anchorId}`, { method: 'DELETE' });
      if (response.ok) {
        onResult(true);
      } else {
        // new Audio(errorAlarme).play();
        throw Error(await response.text());
      }
    }
    /* REMOVE NOTIFICAÇÃO */
    if (notificationId) {
      const responseNotification = await fetch(`/api/notifications/${notificationId}`, { method: 'DELETE' });
      if (responseNotification.ok) {
        onResult(true);
      } else {
        // new Audio(errorAlarme).play();
        throw Error(await responseNotification.text());
      }
    }
    /* REMOVE COMMANDO commands/54 */
    if (commandId) {
      const responseCommand = await fetch(`/api/commands/${commandId}`, { method: 'DELETE' });
      if (responseCommand.ok) {
        onResult(true);
      } else {
        // new Audio(errorAlarme).play();
        throw Error(await responseCommand.text());
      }
    }

    /* ATUALIZA DEVICE */
    const responseDevice = await fetch(`/api/devices/${device?.id}`);
    const dataDevice = await responseDevice.json();
    const attDevice = {
      ...dataDevice,
      attributes: { ...dataDevice.attributes, lockOnExit: false, fence_id: 0, command_id: 0, notification_id: 0 },
    };
    const updateDevice = await fetch(`/api/devices/${device?.id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(attDevice),
    });
    if (!updateDevice.ok) {
      toast.error('Não foi possivel atualizar o dispositivo!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      throw Error(await updateDevice.text());
    }

    onResult(true);
    navigate('/geofences');
    setTimeout(async () => {
      toast.success('Ancora desativada com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      await new Audio(removeFence).play();
      navigate('/');
    }, 3000);
  };

  return (
    <>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        open={open}
        onClose={close}
      >
        <DialogTitle id="alert-dialog-title" sx={{ m: 0, p: 2 }}>
          DESATIVAR ÂNCORA
          <IconButton sx={{ position: 'absolute', right: 8, top: 14 }} onClick={close}>
            <CloseIcon fontSize="small" />
          </IconButton>

        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            Deseja desativar a âncora para o dispositivo
            {' '}
            <strong>{device?.name}</strong>
            {' '}
            ?
          </DialogContentText>

        </DialogContent>
        <DialogActions style={{ paddingBottom: 15, paddingRight: 20, paddingTop: 15 }}>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            title="Cancelar"
            onClick={close}
          >
            Cancelar
          </Button>
          <Button
            size="small"
            color="error"
            variant="contained"
            title="Remover Âncora"
            onClick={handleDeleteGetGeofence}
          >
            Desativar
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </>
  );
};
export default RemoveAnchorGeofenceDialog;
