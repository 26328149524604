import { loadImage, prepareIcon } from './mapUtil';

import directionSvg from '../../resources/images/direction.svg';
import backgroundSvg from '../../resources/images/bc2.png';
import animalPng from '../../resources/images/icon/animal.png';
import bicyclePng from '../../resources/images/icon/bicycle.png';
import boatPng from '../../resources/images/icon/boat.png';
import busPng from '../../resources/images/icon/bus.png';
import cranePng from '../../resources/images/icon/crane.png';
import carPng from '../../resources/images/icon/car.png';
import defaultSvg from '../../resources/images/icon/def.svg';
import helicopterPng from '../../resources/images/icon/helicopter.png';
import motorcyclePng from '../../resources/images/icon/motorcycle.png';
import offroadPng from '../../resources/images/icon/offroad.png';
import personPng from '../../resources/images/icon/person.png';
import pickupPng from '../../resources/images/icon/pickup.png';
import planePng from '../../resources/images/icon/plane.png';
import scooterPng from '../../resources/images/icon/scooter.png';
import shipPng from '../../resources/images/icon/ship.png';
import tractorPng from '../../resources/images/icon/tractor.png';
import trainSvg from '../../resources/images/icon/train.svg';
import tramSvg from '../../resources/images/icon/tram.svg';
import trolleybusSvg from '../../resources/images/icon/trolleybus.svg';
import truckPng from '../../resources/images/icon/truck.png';
import vanPng from '../../resources/images/icon/van.png';
import smartphonePng from '../../resources/images/icon/smartphone.png';

export const mapIcons = {
  animal: animalPng,
  bicycle: bicyclePng,
  boat: boatPng,
  bus: busPng,
  car: carPng,
  crane: cranePng,
  default: defaultSvg,
  helicopter: helicopterPng,
  motorcycle: motorcyclePng,
  offroad: offroadPng,
  person: personPng,
  pickup: pickupPng,
  plane: planePng,
  scooter: scooterPng,
  ship: shipPng,
  tractor: tractorPng,
  train: trainSvg,
  tram: tramSvg,
  trolleybus: trolleybusSvg,
  truck: truckPng,
  van: vanPng,
  smartphone: smartphonePng,
};

export const mapIconKey = (category) => (mapIcons.hasOwnProperty(category) ? category : 'default');

export const mapImages = {};

export default async () => {
  const background = await loadImage(backgroundSvg);
  mapImages.background = await prepareIcon(background);
  mapImages.direction = await prepareIcon(await loadImage(directionSvg));
  await Promise.all(Object.keys(mapIcons).map(async (category) => {
    const results = [];
    ['primary', 'positive', 'negative', 'neutral'].forEach((color) => {
      results.push(loadImage(mapIcons[category]).then((icon) => {
        mapImages[`${category}-${color}`] = prepareIcon(icon);
      }));
    });
    await Promise.all(results);
  }));
};
