import AllOutIcon from '@mui/icons-material/AllOut';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import GpsNotFixedIcon from '@mui/icons-material/GpsNotFixed';
import GpsOffIcon from '@mui/icons-material/GpsOff';
import {
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Draggable from 'react-draggable';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  /* GRID CRIADO PARA EXIBIR QTD E STATUS DE DISPOSITVOS  */
  deviceInfoHeader: {
    pointerEvents: 'auto',
    display: 'flex',
    flexDirection: 'row',
    zIndex: 6,
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: '42%',
      right: 8,
      /*   left: theme.spacing(+14), */
    },
    /*  backgroundColor: 'blue', */
  },
  deviceInfo: {
    pointerEvents: 'auto',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: '42%',
      right: 8,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      top: '45%',
      right: 8,
    },
  },
  iconButtomOnline: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 1,
    color: 'green',
  },
  iconButtomOffline: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 1,
    color: 'red',
  },
  iconButtomUnknow: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 1,
    color: 'GrayText',
  },
  iconButtomAll: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 1,
    color: 'indigo',
  },

  tipografyOnline: {
    '&.MuiTypography-root': {
      color: 'green',
      fontSize: 10,
    },
  },
  tipografyOffline: {
    '&.MuiTypography-root': {
      color: 'red',
      fontSize: 10,
    },
  },
  tipografyUnknow: {
    '&.MuiTypography-root': {
      color: 'GrayText',
      fontSize: 10,
    },
  },
  tipografyAll: {
    '&.MuiTypography-root': {
      color: 'indigo',
      fontSize: 10,
    },
  },
}));

const DevicesInfoDragable = () => {
  const classes = useStyles();

  const theme = useTheme();

  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const devices = useSelector((state) => state.devices.items);
  const deviceStatusCount = (status) => Object.values(devices).filter((d) => d.status === status).length;

  return (
    <div className={classes.root}>
      {desktop ? (
        <Draggable
          axis="both"
          handle=".handle"
          defaultPosition={{ x: 0, y: 0 }}
          position={null}
          grid={[50, 50]}
          scale={1}
          bounds="parent"
        >
          <div className={classes.deviceInfoHeader}>
            <div className="handle" style={desktop ? { display: 'flex', flexDirection: 'column' } : { display: 'flex', flexDirection: 'column' }}>

              <Tooltip title="ONLINE" placement="left">
                <Paper sx={{ width: 35, display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
                  <IconButton className={classes.iconButtomOnline}>
                    <Typography classes={{ root: classes.tipografyOnline }}>
                      {deviceStatusCount('online')}
                    </Typography>
                    <GpsFixedIcon fontSize="small" sx={{ ml: 0.1 }} />
                  </IconButton>
                </Paper>
              </Tooltip>
              <Tooltip title="OFFLINE" placement="left">
                <Paper sx={{ width: 35, display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
                  <IconButton className={classes.iconButtomOffline}>
                    <Typography classes={{ root: classes.tipografyOffline }}>
                      {deviceStatusCount('offline')}
                    </Typography>
                    <GpsOffIcon fontSize="small" sx={{ ml: 0.1 }} />
                  </IconButton>
                </Paper>
              </Tooltip>
              <Tooltip title="DEESCONHECIDO" placement="left">
                <Paper sx={{ width: 35, display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
                  <IconButton className={classes.iconButtomUnknow}>
                    <Typography classes={{ root: classes.tipografyUnknow }}>
                      {deviceStatusCount('unknown')}
                    </Typography>
                    <GpsNotFixedIcon fontSize="small" sx={{ ml: 0.1 }} />
                  </IconButton>
                </Paper>
              </Tooltip>
              <Tooltip title="TOTAL" placement="left">
                <Paper sx={{ width: 35, display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
                  <IconButton className={classes.iconButtomAll}>
                    <Typography classes={{ root: classes.tipografyAll }}>
                      {deviceStatusCount('online') + deviceStatusCount('offline') + deviceStatusCount('unknown')}
                    </Typography>
                    <AllOutIcon fontSize="small" />
                  </IconButton>
                </Paper>
              </Tooltip>
            </div>
          </div>
        </Draggable>
      ) : (
        <Draggable
          axis="both"
          handle=".handle"
          defaultPosition={{ x: 0, y: 10 }}
          position={null}
          grid={[50, 50]}
          scale={1}
          bounds="parent"
        >
          <div className={classes.deviceInfo}>
            <div className="handle" style={desktop ? { display: 'flex', flexDirection: 'row' } : { display: 'flex', flexDirection: 'column' }}>
              <Tooltip title="ONLINE" placement="left">
                <Paper sx={{ width: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
                  <IconButton className={classes.iconButtomOnline}>
                    <Typography classes={{ root: classes.tipografyOnline }}>
                      {deviceStatusCount('online')}
                    </Typography>
                    <GpsFixedIcon fontSize="small" sx={{ ml: 0.1 }} />
                  </IconButton>
                </Paper>
              </Tooltip>
              <Tooltip title="OFFLINE" placement="left">
                <Paper sx={{ width: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
                  <IconButton className={classes.iconButtomOffline}>
                    <Typography classes={{ root: classes.tipografyOffline }}>
                      {deviceStatusCount('offline')}
                    </Typography>
                    <GpsOffIcon fontSize="small" sx={{ ml: 0.1 }} />
                  </IconButton>
                </Paper>
              </Tooltip>
              <Tooltip title="DEESCONHECIDO" placement="left">
                <Paper sx={{ width: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
                  <IconButton className={classes.iconButtomUnknow}>
                    <Typography classes={{ root: classes.tipografyUnknow }}>
                      {deviceStatusCount('unknown')}
                    </Typography>
                    <GpsNotFixedIcon fontSize="small" sx={{ ml: 0.1 }} />
                  </IconButton>
                </Paper>
              </Tooltip>
              <Tooltip title="TOTAL" placement="left">
                <Paper sx={{ width: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
                  <IconButton className={classes.iconButtomAll}>
                    <Typography classes={{ root: classes.tipografyAll }}>
                      {deviceStatusCount('online') + deviceStatusCount('offline') + deviceStatusCount('unknown')}
                    </Typography>
                    <AllOutIcon fontSize="small" />
                  </IconButton>
                </Paper>
              </Tooltip>
            </div>
          </div>
        </Draggable>
      )}

    </div>
  );
};

export default DevicesInfoDragable;
