import AnchorIcon from '@mui/icons-material/Anchor';
import Battery20Icon from '@mui/icons-material/Battery20';
import Battery60Icon from '@mui/icons-material/Battery60';
import BatteryCharging20Icon from '@mui/icons-material/BatteryCharging20';
import BatteryCharging60Icon from '@mui/icons-material/BatteryCharging60';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import MovingIcon from '@mui/icons-material/Moving';
import StopIcon from '@mui/icons-material/Stop';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import WifiIcon from '@mui/icons-material/Wifi';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCarBattery } from '@fortawesome/free-solid-svg-icons';

import {
  Avatar,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from '../common/components/LocalizationProvider';
import {
  formatBoolean,
  formatPercentage,
  formatSpeed,
  formatCourse,
  formatNumber,
} from '../common/util/formatter';
import { useAdministrator } from '../common/util/permissions';
import { useAttributePreference } from '../common/util/preferences';
import { mapIconKey, mapIcons } from '../map/core/preloadImages';
import { ReactComponent as EngineIcon } from '../resources/images/data/engine.svg';
import { devicesActions } from '../store';

const useStyles = makeStyles((theme) => ({
  icon: {
    display: 'flex',
    width: '45px',
    height: '45px',
    /*  filter: 'brightness(0) invert(1)', */
  },
  media: {
    display: 'flex',
    width: '50px',
    height: '50px',
    borderRadius: '10%',
    objectFit: 'cover',
  },
  batteryText: {
    fontSize: '0.75rem',
    fontWeight: 'normal',
    lineHeight: '0.875rem',
  },
  key: {
    transform: 'rotate(180deg)',
  },
  conatinerGrid: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 13,
    minWidth: '100%',
  },
  primaryText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingLeft: 13,
    width: '100%',
    minWidth: '100%',
  },
  secondaryTextTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    minWidth: '100%',
  },
  secondaryText: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    minWidth: '100%',
  },
  secondaryTextIcons: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    minWidth: '100%',
  },
  positive: {
    color: theme.palette.colors.positive,
  },
  medium: {
    color: theme.palette.colors.medium,
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  neutral: {
    color: theme.palette.colors.neutral,
  },
  blinkingIcon: {
    animation: '$blink 1s infinite', // Aplica a animação de piscar no ícone que possuir a classe "blinkingIcon"
  },
  '@keyframes blink': {
    '20%': { opacity: 1 },
    '50%': { opacity: 0 },
    '100%': { opacity: 1 },
  },
}));

const DeviceRow = ({ data, index, style }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslation();

  const admin = useAdministrator();

  const item = data[index];
  const position = useSelector((state) => state?.session?.positions[item.id]);
  const device = useSelector((state) => state?.devices?.items[item.id]);

  const deviceImage = device?.attributes?.deviceImage;
  const deviceId = device?.uniqueId;
  const [isAnchor, setIsAnchor] = useState(false);
  const [, setDeviceInfo] = useState('');

  const devicePrimary = useAttributePreference('devicePrimary', 'name');
  const deviceSecondary = useAttributePreference('deviceSecondary', '');
  const primaryText = () => (
    <div className={classes.primaryText}>
      {item[devicePrimary]}
    </div>
  );
  const secondaryText = () => (
    <div className={classes.conatinerGrid}>
      <div className={classes.primaryTextTitle}>
        {deviceSecondary && item[deviceSecondary] && `${item[deviceSecondary]}`}
      </div>
      <div className={classes.secondaryText}>
        {/* FORMATADO DATA E HORA PARA PORTUGUES */}
        {` ${moment(position?.fixTime).format('DD/MM/YYYY HH:mm:ss')} | ${formatSpeed(position?.speed || 0, position?.attributes?.speedUnit || 'kmh', t)}`}
      </div>
      {/* {moment(positions[index].fixTime).format('DD/MM/YYYY HH:mm:ss')}  */}
      <div className={classes.secondaryTextIcons}>
        {/* INFORMAÇÕES DE DIREÇÃO DO DISPOSITIVO */}
        <Tooltip title="Direção">
          {formatCourse(position?.course)}
        </Tooltip>
        {/* INFORMAÇAO SOBRE SATELITES DO DISPOSITIVOS */}
        {(position?.attributes?.sat || position?.attributes?.sat !== undefined) && item?.status === 'online' && (
          position?.attributes?.sat !== undefined && position?.attributes?.sat > 0 && item?.status === 'online' ? (
            <Tooltip title={position?.attributes?.sat}>
              <IconButton size="small">
                <SatelliteAltIcon
                  fontSize="small"
                  className={classes.positive}
                />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={position?.attributes?.sat}>
              <IconButton size="small">
                <SatelliteAltIcon
                  fontSize="small"
                  className={classes.negative}
                />
              </IconButton>
            </Tooltip>
          )
        )}
        {(position?.attributes?.sat > 0 && item?.status !== 'online') && (
          <Tooltip title={position?.attributes?.sat}>
            <IconButton size="small">
              <SatelliteAltIcon
                fontSize="small"
                className={classes.neutral}
              />
            </IconButton>
          </Tooltip>
        )}

        {item?.status === 'online' ? (
          <Tooltip title="Online">
            <IconButton size="small">
              <WifiIcon fontSize="small" className={classes.positive} />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Offline">
            <IconButton size="small">
              <WifiOffIcon fontSize="small" className={classes.negative} />
            </IconButton>
          </Tooltip>
        )}
        {position?.attributes?.motion && item?.status === 'online' ? (
          <Tooltip title="Em Movimento">
            <IconButton size="small">
              <MovingIcon fontSize="small" className={classes.negative} />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Parado">
            <IconButton size="small">
              <StopIcon fontSize="small" className={classes.neutral} />
            </IconButton>
          </Tooltip>
        )}
        {isAnchor && (
          <Tooltip title="Âncora Ativada">
            <IconButton size="small">
              <AnchorIcon
                fontSize="small"
                width={20}
                height={20}
                className={classes.negative}
              />
            </IconButton>
          </Tooltip>
        )}
        {!isAnchor && (
          <Tooltip title="Âncora">
            <IconButton size="small">
              <AnchorIcon
                fontSize="small"
                width={20}
                height={20}
                className={classes.neutral}
              />
            </IconButton>
          </Tooltip>
        )}

        {(((position?.attributes?.event === 'jt') || (position?.attributes?.blocked === true)
          || (position?.attributes?.out1 === true))
          && (item?.status === 'online')) &&
          (
            <Tooltip title="Bloqueado">
              <IconButton size="small">
                <LockIcon
                  fontSize="small"
                  className={classes.negative}
                />
              </IconButton>
            </Tooltip>
          )}
        {(((position?.attributes?.event === 'kt') || (position?.attributes?.blocked === false)
          || (position?.attributes?.out1 === false))
          && (item?.status === 'online')) &&
          (
            <Tooltip title="Liberado">
              <IconButton size="small">
                <LockOpenIcon
                  fontSize="small"
                  color="success"
                />
              </IconButton>
            </Tooltip>
          )}
        {
          (((position?.attributes?.event !== 'kt') && (position?.attributes?.event !== 'jt') &&
            (position?.attributes?.blocked !== true) && (position?.attributes?.blocked !== false)) &&
            (position?.attributes?.out1 !== true) && (position?.attributes?.out1 !== false)) && (
            (position?.protocol !== 'osmand') && (item?.status === 'online')) && (
            <Tooltip title="Obtendo Status">
              <IconButton size="small">
                <LockIcon
                  fontSize="small"
                  className={classes.neutral}
                />
              </IconButton>
            </Tooltip>
          )
        }
        {item?.status !== 'online' && position?.protocol !== 'osmand' && (
          <Tooltip title="Obtendo Status">
            <IconButton size="small">
              <LockIcon
                fontSize="small"
                className={classes.neutral}
              />
            </IconButton>
          </Tooltip>
        )}
        {/* ALARME NATIVO TRACCAR */}
        {/*  {position?.attributes.hasOwnProperty('alarm') && item?.status === 'online' && (
          <Tooltip title={`${t('eventAlarm')}: ${formatAlarm(position?.attributes?.alarm, t)}`}>
            <IconButton size="small">
              <NotificationsActiveIcon fontSize="small" className={classes.negative} />
            </IconButton>
          </Tooltip>
        )} */}
        {(position?.attributes?.alarm === 'powerCut' && position?.attributes?.charge === false && item?.status === 'online')
          || (position?.attributes?.alarm === 'powerCut' && (position?.attributes?.charge === false
            || position?.attributes?.charge === undefined) && item?.status === 'online') ?
          (
            <Tooltip title="Alimentação Cortada">
              <IconButton size="small">
                <NotificationsActiveIcon fontSize="small" className={`${classes.negative} ${classes.blinkingIcon}`} />
              </IconButton>
            </Tooltip>
          ) : (
            (position?.attributes?.alarm === 'lowBattery' && position?.attributes?.charge === false && item?.status === 'online')
            || (position?.attributes?.charge === undefined && position?.attributes?.batteryLevel < 30 && item?.status === 'online')) && (
            <Tooltip title="Bateria Baixa">
              <IconButton size="small">
                <NotificationsActiveIcon fontSize="small" className={`${classes.negative} ${classes.blinkingIcon}`} />
              </IconButton>
            </Tooltip>
          )}
        {position?.attributes.hasOwnProperty('ignition') && item?.status === 'online' && (
          <Tooltip title={`${t('positionIgnition')}: ${formatBoolean(position?.attributes?.ignition, t)}`}>
            <IconButton size="small">
              {position?.attributes?.ignition ? (
                <EngineIcon width={20} height={22} className={classes.key} style={{ color: 'green' }} />
              ) : (
                <EngineIcon width={20} height={22} className={classes.key} style={{ color: 'red' }} />
              )}
            </IconButton>
          </Tooltip>
        )}
        {item?.status !== 'online' && position?.protocol !== 'osmand' && (
          <Tooltip title={`${t('positionIgnition')}: ${formatBoolean(position?.attributes?.ignition, t)}`}>
            <IconButton size="small">
              <EngineIcon width={20} height={22} className={classes.key} style={{ color: 'grey' }} />
            </IconButton>
          </Tooltip>
        )}
        {/* EXIBE INFORMAÇÃO DE BATERIA */}
        {item?.status === 'online' && position?.attributes?.batteryLevel && (
          <Tooltip title={`${t('positionBatteryLevel')}: ${formatPercentage(position?.attributes?.batteryLevel)}`}>
            {/* <Tooltip title={`${t('positionBatteryLevel')}: ${formatPercentage(position?.attributes?.batteryLevel === 4000 && position?.attributes?.charge ? 100 : position?.attributes?.batteryLevel)}`}> */}
            <IconButton size="small">
              {position?.attributes?.batteryLevel > 70 ? (
                position?.attributes?.charge
                  ? (<BatteryChargingFullIcon fontSize="small" className={classes.positive} />)
                  : (<BatteryFullIcon fontSize="small" className={classes.positive} />)
              ) : position?.attributes?.batteryLevel > 30 ? (
                position?.attributes?.charge
                  ? (<BatteryCharging60Icon fontSize="small" className={classes.medium} />)
                  : (<Battery60Icon fontSize="small" className={classes.medium} />)
              ) : position?.attributes?.batteryLevel === undefined ? (
                position?.attributes?.charge === undefined
                  ? (<BatteryFullIcon fontSize="small" className={classes.neutral} />)
                  : (<BatteryFullIcon fontSize="small" className={classes.neutral} />)
              ) : (
                position?.attributes?.charge
                  ? (<BatteryCharging20Icon fontSize="small" className={classes.negative} />)
                  : (<Battery20Icon fontSize="small" className={classes.negative} />)
              )}
              {/* VERFICA SE BATERIA DO GT-06 ESTA COM 4000% E CORRIGE */}
              {position?.attributes?.batteryLevel && (
                <Typography sx={{ fontSize: 11 }}>
                  {formatPercentage(position?.attributes?.batteryLevel === 4000 ? 100 : position?.attributes?.batteryLevel)}
                </Typography>
              )}
            </IconButton>
          </Tooltip>
        )}
        {(item?.status !== 'online') && position?.attributes?.batteryLevel && (
          <Tooltip title={`${t('positionBatteryLevel')}: ${formatPercentage(position?.attributes?.batteryLevel)}`}>
            <IconButton size="small">
              <BatteryFullIcon fontSize="small" className={classes.neutral} />
              {position?.attributes?.batteryLevel && (
                <Typography sx={{ fontSize: 11 }}>
                  {formatPercentage(position?.attributes?.batteryLevel === 4000 ? 100 : position?.attributes?.batteryLevel)}
                </Typography>
              )}
            </IconButton>
          </Tooltip>
        )}

        {/* INICIO -  EXIBE INFORMAÇÃO DE BATERIA DO VEICULO PROTOCOLOS:  EASYTRACKER, J164G, NT20, E3+ */}
        {item?.status === 'online' && position?.protocol === 'easytrack' && position?.attributes?.power && (
          <Tooltip title={`${t('positionBatteryLevel')}: ${formatPercentage(position?.attributes?.power)}`}>
            <IconButton size="small">
              {position?.attributes?.power > 70 ? (
                position?.attributes?.charge
                  ? (<BatteryChargingFullIcon fontSize="small" className={classes.positive} />)
                  : (<BatteryFullIcon fontSize="small" className={classes.positive} />)
              ) : position?.attributes?.power > 30 ? (
                position?.attributes?.charge
                  ? (<BatteryCharging60Icon fontSize="small" className={classes.medium} />)
                  : (<Battery60Icon fontSize="small" className={classes.medium} />)
              ) : position?.attributes?.power === undefined ? (
                position?.attributes?.charge === undefined
                  ? (<BatteryFullIcon fontSize="small" className={classes.neutral} />)
                  : (<BatteryFullIcon fontSize="small" className={classes.neutral} />)
              ) : (
                position?.attributes?.charge
                  ? (<BatteryCharging20Icon fontSize="small" className={classes.negative} />)
                  : (<Battery20Icon fontSize="small" className={classes.negative} />)
              )}
              {position?.attributes?.power && (
                <Typography sx={{ fontSize: 11 }}>
                  {formatPercentage(position?.attributes?.power)}
                </Typography>
              )}
            </IconButton>
          </Tooltip>
        )}
        {/* easytracker */}
        {item?.status !== 'online' && position?.protocol === 'easytrack' && position?.attributes?.power && (
          <Tooltip title={`${t('positionBatteryLevel')}: ${formatPercentage(position?.attributes?.power)}`}>
            <IconButton size="small">
              <BatteryFullIcon fontSize="small" className={classes.neutral} />
              {position?.attributes?.power && (
                <Typography sx={{ fontSize: 10 }}>
                  {formatPercentage(position?.attributes?.power)}
                </Typography>
              )}
            </IconButton>
          </Tooltip>
        )}
        {/* FIM - EXIBE INFORMAÇÃO DE BATERIA DO VEICULO PROTOCOLOS:  EASYTRACKER, J164G, NT20, E3+ */}
        {/* EXIBE INFORMAÇÃO DE TENSAO DO VEICULO PROTOCOLO EASYTRACKER, GT06, J16 */}
        {item?.status === 'online' && position?.attributes?.adc1 >= 0 && (
          <Tooltip title={`Tensão Bateria do Veículo: ${formatNumber(position?.attributes?.adc1)}v`}>
            <IconButton size="small">
              {position?.attributes?.adc1 < 11.5 ? (
                <FontAwesomeIcon icon={faCarBattery} className={classes.negative} />
              ) : (
                <FontAwesomeIcon icon={faCarBattery} className={classes.positive} />
              )}
              {position?.attributes?.adc1 >= 0 && (
                <Typography sx={{ fontSize: 11, paddingLeft: 0.5 }}>
                  {`${formatNumber(position?.attributes?.adc1)}v`}
                </Typography>
              )}
            </IconButton>
          </Tooltip>
        )}
        {/* INICIO - EXIBE INFORMAÇÃO DE TENSAO DO VEICULO PROTOCOLOS: E3+, J16 */}
        {item?.status !== 'online' && position?.attributes?.adc1 && (
          <Tooltip title={`Tensão Bateria do Veículo: ${formatNumber(position?.attributes?.adc1)}v`}>
            <IconButton size="small">
              <FontAwesomeIcon icon={faCarBattery} className={classes.neutral} />
              {position?.attributes?.adc1 && (
                <Typography sx={{ fontSize: 11, paddingLeft: 0.5 }}>
                  {`${formatNumber(position?.attributes?.adc1)}v`}
                </Typography>
              )}
            </IconButton>
          </Tooltip>
        )}
        {/* EXIBE INFORMAÇÃO DE TENSAO DA BATERIA DO VEICULO PROTOCOLO suntech */}
        {item?.status === 'online' && position?.protocol === 'suntech' && position?.attributes?.power >= 0 && (
          <Tooltip title={`Tensão Bateria do Veículo: ${formatNumber(position?.attributes?.power)}v`}>
            <IconButton size="small">
              {position?.attributes?.power < 11.5 ? (
                <FontAwesomeIcon icon={faCarBattery} className={classes.negative} />
              ) : (
                <FontAwesomeIcon icon={faCarBattery} className={classes.positive} />
              )}
              {position?.attributes?.power >= 0 && (
                <Typography sx={{ fontSize: 11, paddingLeft: 0.5 }}>
                  {`${formatNumber(position?.attributes?.power)}v`}
                </Typography>
              )}
            </IconButton>
          </Tooltip>
        )}
        {/* INICIO - EXIBE INFORMAÇÃO DE TENSAO DA BATERIA DO VEICULO PROTOCOLOS: SUNTECH */}
        {item?.status !== 'online' && position?.protocol === 'suntech' && position?.attributes?.power && (
          <Tooltip title={`Tensão Bateria do Veículo: ${formatNumber(position?.attributes?.power)}v`}>
            <IconButton size="small">
              <FontAwesomeIcon icon={faCarBattery} className={classes.neutral} />
              {position?.attributes?.power && (
                <Typography sx={{ fontSize: 11, paddingLeft: 0.5 }}>
                  {`${formatNumber(position?.attributes?.power)}v`}
                </Typography>
              )}
            </IconButton>
          </Tooltip>
        )}
      </div>
    </div>
  );

  /* FUNÇÃO PARA OBTER O TIPO DE PROTOCOLO */
  const getDeviceInfo = async () => {
    const response = await fetch(`/api/positions?deviceId=${device.id}`);
    if (response.ok) {
      const data = await response.json();
      setDeviceInfo(data[0]);
    } else {
      throw Error(await response.text());
    }
  };
  useEffect(() => { if (device?.id) { getDeviceInfo(); } }, [device, position]);
  /* VERIFICA SE EXISTE ANCORA PARA O DISPOSITIVO */
  const verifyGetGeofences = async () => {
    const verifyGeofences = await fetch(`/api/geofences/?deviceId=${device?.id}`);
    if (verifyGeofences.ok) {
      const data = await verifyGeofences.json();
      if ((data[0]?.attributes?.isAnchor === true)) {
        setIsAnchor(true);
      } else {
        setIsAnchor(false);
      }
    }
  };
  useEffect(() => { verifyGetGeofences(); }, [device, position]);
  return (
    <div style={style}>
      {/* NAO REMOVER ESSE style SENAO QUEBRA O GRID DA APLICAÇÃO, TRABALHO EM CONJUNTO COM DeviceList-itemSize={110} */}
      <ListItemButton
        key={item?.id}
        onClick={() => dispatch(devicesActions.selectId(item?.id))}
        disabled={!admin && item?.disabled}
        alignItems="center"
      >
        <ListItemAvatar>
          {/* INSERE A IMAGEM DO DISPOSITIVO NO GRID CASO POSSUA */}
          {deviceImage ? (
            <Avatar
              sx={{ width: 50, height: 50, borderRadius: 2 }}
            >
              <img
                className={classes.media}
                src={`/api/media/${deviceId}/${deviceImage}`}
                alt={device?.name}
              />
            </Avatar>

          ) : (
            <Avatar
              sx={{
                width: 50,
                height: 50,
                bgcolor: 'transparent',
                borderRadius: 2,
                border: `1px solid ${item.status === 'online' ? 'green' : 'red'}`,
              }}
            >
              <img
                className={classes.icon}
                src={mapIcons[mapIconKey(item?.category)]}
                alt="Icone do Dispositivo"
              />
            </Avatar>
          )}

        </ListItemAvatar>

        <ListItemText
          primary={primaryText()}
          primaryTypographyProps={{ noWrap: true }}
          secondary={secondaryText()}
          secondaryTypographyProps={{ noWrap: true }}
        />
      </ListItemButton>
    </div>
  );
};

export default DeviceRow;
