import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';

async function eventsPDF(data, registers, from, to, devices, geofences) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  /* TTITULO DO PDF */
  const reportTitle = [
    {
      text: 'Relatório de Eventos',
      fontSize: 12,
      bold: true,
      margin: [20, 20, 0, 2], /* LEFT, TOP, RIGHT, BOTTOM */
    },
    {
      text: `Período : ${moment(from).format('DD/MM/YYYY HH:mm:ss')} - ${moment(to).format('DD/MM/YYYY HH:mm:ss')}`,
      fontSize: 12,
      bold: true,
      margin: [20, 2, 0, 2], /* LEFT, TOP, RIGHT, BOTTOM */
    },
    {
      text: `Registros Encontrados: ${registers}`,
      fontSize: 12,
      bold: true,
      margin: [20, 2, 0, 20], /* LEFT, TOP, RIGHT, BOTTOM */
    },

  ];
  const dados = data.map((item) => {
    let types = '';
    const d = {
      alarm: 'Alarme',
      vibration: 'Vibração',
      powerCut: 'Energia Cortada',
      lowBattery: 'Bateria Baixa',
      deviceOnline: 'Dispositivo ONLINE',
      deviceOffline: 'Dispositivo OFFLINE',
      deviceUnknown: 'Status Desconhecido',
      deviceMoving: 'Dispositivo em Movimento',
      deviceStopped: 'Dispositivo Parou',
      deviceInactive: 'Dispositivo Inativo',
      deviceOverspeed: 'Excedeu Limite de Velocidade',
      geofenceEnter: 'Dispositivo Entrou na Cerca',
      geofenceExit: 'Dispositivo Saiu da Cerca',
      ignitionOn: 'Ignição Ligada',
      ignitionOff: 'Ignição Desligada',
      driverChanged: 'Motorista Alterado',
      media: 'Midia',
      textMessage: 'Mensagem de Texto',
      sos: 'SOS',
    };

    if (item?.attributes?.alarm === 'powerCut') {
      types = d.powerCut;
    } else if (item?.attributes?.alarm === 'vibration') {
      types = d.vibration;
    } else if (item?.attributes?.alarm === 'lowBattery') {
      types = d.lowBattery;
    } else if (item?.attributes?.alarm === 'sos') {
      types = d.sos;
    } else if (item?.attributes?.alarm === '') {
      types = d.deviceUnknown;
    } else if (item?.type === '') {
      types = d.deviceUnknown;
    } else if (item?.type === 'deviceOnline') {
      types = d.deviceOnline;
    } else if (item?.type === 'deviceOffline') {
      types = d.deviceOffline;
    } else if (item?.type === 'deviceUnknown') {
      types = d.deviceUnknown;
    } else if (item?.type === 'deviceMoving') {
      types = d.deviceMoving;
    } else if (item?.type === 'deviceStopped') {
      types = d.deviceStopped;
    } else if (item?.type === 'ignitionOff') {
      types = d.ignitionOff;
    } else if (item?.type === 'ignitionOn') {
      types = d.ignitionOn;
    } else if (item?.type === 'geofenceEnter') {
      types = d.geofenceEnter;
    } else if (item?.type === 'geofenceExit') {
      types = d.geofenceExit;
    } else if (item?.type === 'deviceInactive') {
      types = d.deviceInactive;
    } else if (item?.type === 'deviceOverspeed') {
      types = d.deviceOverspeed;
    } else if (item?.type === 'driverChanged') {
      types = d.driverChanged;
    } else if (item?.type === 'media') {
      types = d.media;
    } else if (item?.type === 'textMessage') {
      types = d.textMessage;
    }

    return [
      { text: devices[item?.deviceId]?.name, fontSize: 9, margin: [0, 2, 0, 2] },
      { text: moment(item?.eventTime).format('DD/MM/YYYY HH:mm:ss'), fontSize: 9, margin: [0, 2, 0, 2] },
      { text: `${types !== '' ? types : 'Status Desconhecido'}`, fontSize: 9, margin: [0, 2, 0, 2] },
      { text: geofences[item?.geofenceId]?.name, fontSize: 9, margin: [0, 2, 0, 2] },
      { text: devices[item?.maintenanceId]?.name, fontSize: 9, margin: [0, 2, 0, 2] },
    ];
  });
  /* DETALHES DO PDF */
  const details = [
    {
      table: {
        headerRows: 1,
        /* widths: ['*', '*', '*', '*', '*', '*'], */
        widths: ['auto', 'auto', 'auto', 'auto', 'auto'],
        alignment: 'center',
        body: [
          [
            { text: 'Dispositivo', style: 'tableHeader', fontSize: 10 },
            { text: 'Data/Hora', style: 'tableHeader', fontSize: 10 },
            { text: 'Evento', style: 'tableHeader', fontSize: 10 },
            { text: 'Geocerca', style: 'tableHeader', fontSize: 10 },
            { text: 'Manutenção', style: 'tableHeader', fontSize: 10 },
          ],
          ...dados,
        ],
      },
      layout: 'lightHorizontalLines', // headerLineOnly
    },
  ];

  /* RODAPE DO PDF */
  function Rodape(currentPage, pageCount) {
    return [
      {
        text: `${currentPage}/${pageCount}`,
        alignment: 'right',
        fontSize: 9,
        margin: [0, 10, 20, 0], /* LEFT, TOP, RIGHT, BOTTOM */
      },
    ];
  }

  const docDefinitions = {
    pageSize: 'A4',
    pageMargins: [20, 90, 10, 60], /* LEFT, TOP, RIGHT, BOTTOM */
    watermark: { text: 'SisalTrack', color: 'blue', opacity: 0.3, bold: true, italics: false }, /* Marca dágua */
    header: [reportTitle], /* cabeçalho */
    content: [details], /* conteudo */
    footer: Rodape, /* rodape */
  };
  const pdfName = 'Relatorio-de-Eventos.pdf';
  pdfMake.createPdf(docDefinitions).download(pdfName);
}
export default eventsPDF;
