import AnchorIcon from '@mui/icons-material/Anchor';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PendingIcon from '@mui/icons-material/Pending';
import PublishIcon from '@mui/icons-material/Publish';
import ReplayIcon from '@mui/icons-material/Replay';
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useCatch, useCatchCallback } from '../../reactHelper';
import { devicesActions } from '../../store';
import usePositionAttributes from '../attributes/usePositionAttributes';
import { useDeviceReadonly } from '../util/permissions';
import { useAttributePreference } from '../util/preferences';
import AnchorGeofenceDialog from './AnchorGeofenceDialog';
import { useTranslation } from './LocalizationProvider';
import PositionValue from './PositionValue';
import RemoveAnchorGeofenceDialog from './RemoveAnchorGeofenceDialog';
import RemoveDialog from './RemoveDialog';
import StopResumeDialog from './StopResumeDialog';

const useStyles = makeStyles((theme) => ({
  card: {
    pointerEvents: 'auto',
    width: theme.dimensions.popupMaxWidth,
  },
  media: {
    height: theme.dimensions.popupImageHeight,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  mediaButton: {
    color: theme.palette.colors.white,
    mixBlendMode: 'difference',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 1, 0, 2),
  },
  headerCardImage: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 2,
    paddingTop: 2,
  },
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  icon: {
    width: '25px',
    height: '25px',
    filter: 'brightness(0) invert(1)',
  },
  table: {
    '& .MuiTableCell-sizeSmall': {
      paddingLeft: 0,
      paddingRight: 5,
    },
  },
  cell: {
    borderBottom: 'none',
  },
  actions: {
    justifyContent: 'space-between',
  },
  root: ({ desktopPadding }) => ({
    pointerEvents: 'none',
    position: 'fixed',
    zIndex: 5,
    left: '50%',
    [theme.breakpoints.up('md')]: {
      left: `calc(50% + ${desktopPadding} / 2)`,
      bottom: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      left: '46%', /* padrão 50% */
      bottom: `calc(${theme.spacing(3)} + ${theme.dimensions.bottomBarHeight}px)`,
    },
    transform: 'translateX(-50%)',
  }),
}));

const StatusRow = ({ name, content }) => {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell className={classes.cell}>
        <Typography variant="body2">{name}</Typography>
      </TableCell>
      <TableCell className={classes.cell}>
        <Typography variant="body2" color="textSecondary">{content}</Typography>
      </TableCell>
    </TableRow>
  );
};

const StatusCard = ({ deviceId, position, onClose, disableActions, desktopPadding = 0 }) => {
  const classes = useStyles({ desktopPadding });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation();
  const deviceReadonly = useDeviceReadonly();
  const user = useSelector((state) => state?.session?.user);
  const device = useSelector((state) => state.devices.items[deviceId]);

  const deviceImage = device?.attributes?.deviceImage;

  const positionAttributes = usePositionAttributes(t);
  const positionItems = useAttributePreference('positionItems', 'speed,address,totalDistance,course');

  const [anchorEl, setAnchorEl] = useState(null);

  const [removing, setRemoving] = useState(false);
  const [anchorCustomer, setAnchorCustomer] = useState(false);
  const [deletAnchorCustomer, setDeleteAnchorCustomer] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState('');
  const [stopResume, setStopResume] = useState(false);
  const [anchorId, setAnchorId] = useState('');
  const [commandId, setCommandId] = useState('');
  const [notificationId, setNotificationdId] = useState('');
  const [isAnchor, setIsAnchor] = useState(false);

  /* FUNÇÃO PARA OBTER O TIPO DE PROTOCOLO */
  const getDeviceInfo = async () => {
    const response = await fetch(`/api/positions?deviceId=${device?.id}`);
    if (response.ok) {
      const data = await response.json();
      setDeviceInfo(data[0]);
    } else {
      /*  new Audio(errorAlarme).play(); */
      throw Error(await response.text());
    }
  };
  useEffect(() => { if (device?.id) { getDeviceInfo(); } }, [device, position, navigate]);

  /*  CHAMA POPUP PARA CRIAR ANCORA */
  const handleCreateGeofenceAnchor = async () => {
    setAnchorCustomer(true);
  };
  /*  CHAMA POPUP PARA REMOVER ANCORA */
  const handleDeleteGeofenceAnchor = async () => {
    setDeleteAnchorCustomer(true);
  };
  /* VERIFICA SE EXISTE ANCORA PARA O DISPOSITIVO */
  const verifyGetGeofences = async () => {
    const verifyGeofences = await fetch(`/api/geofences/?deviceId=${device?.id}`);
    if (verifyGeofences.ok) {
      const data = await verifyGeofences.json();
      /*   console.log('geo', data); */
      if ((data[0]?.attributes?.isAnchor === true)) {
        setAnchorId(data[0]?.id);
        setIsAnchor(true);
      } else {
        setAnchorId('');
        setIsAnchor(false);
      }
    }
  };
  useEffect(() => { if (device?.id) { verifyGetGeofences(); } }, [device, position, navigate]);
  /* VERIFICA SE EXISTE NOTIFICAÇÃO PARA O DISPOSITIVO */
  const verifyNotificationsDevice = async () => {
    const verifyNotifications = await fetch(`/api/notifications/?deviceId=${device?.id}`);
    if (verifyNotifications.ok) {
      const data = await verifyNotifications.json();
      /*       console.log('noti', data[0]?.id); */
      if (data[0]?.id !== undefined) {
        setNotificationdId(data[0]?.id);
      } else {
        setNotificationdId('');
      }
    }
  };
  useEffect(() => { if (device?.id) { verifyNotificationsDevice(); } }, [device, position, navigate]);
  /* VERIFICA SE EXISTE COMANDO ATRIBUIDO AO DEVICE */
  const verifyCommandsDevice = async () => {
    const verifyCommands = await fetch(`/api/commands/send/?deviceId=${device?.id}`);
    if (verifyCommands.ok) {
      const data = await verifyCommands.json();
      /* console.log('comand', data[0]?.id); */
      if (data[0]?.id !== undefined) {
        setCommandId(data[0]?.id);
      } else {
        setCommandId('');
      }
    }
  };
  useEffect(() => { if (device?.id) { verifyCommandsDevice(); } }, [device, position, navigate]);

  /* INSERIDO FUNÇÃO PARA BLOQUEIO PELO CARD */
  const handleStopResumeEngine = () => {
    /* VERIFICA SE O USUARIO TEM LIMITE DE COMANDOS caso sim
   NÃO AUTORIZA ENVIAR O COMANDO
   */
    if (user?.limitCommands) {
      toast.error('Usuário sem Permissão!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setStopResume(true);
  };
  /* REMOVE ANCORA  NATIVO TRACCAR */
  const handleRemove = useCatch(async (removed) => {
    if (removed) {
      const response = await fetch('/api/devices');
      if (response.ok) {
        dispatch(devicesActions.refresh(await response.json()));
      } else {
        throw Error(await response.text());
      }
    }
    setRemoving(false);
  });
  /* CRIA ANCORA NATIVA DO TRACCAR */
  const handleGeofence = useCatchCallback(async () => {
    const newItem = {
      name: '',
      area: `CIRCLE (${position.latitude} ${position.longitude}, 50)`,
    };
    const response = await fetch('/api/geofences', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newItem),
    });
    if (response.ok) {
      const item = await response.json();
      const permissionResponse = await fetch('/api/permissions', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ deviceId: position.deviceId, geofenceId: item.id }),
      });
      if (!permissionResponse.ok) {
        throw Error(await permissionResponse.text());
      }
      navigate(`/settings/geofence/${item.id}`);
    } else {
      throw Error(await response.text());
    }
  }, [navigate, position]);

  return (
    <>
      <div className={classes.root}>

        {device && (
          <Draggable
            handle={`.${classes.media}, .${classes.header}`}
          >
            <Card elevation={3} className={classes.card}>
              {deviceImage ? (
                <>
                  <div className={classes.headerCardImage}>
                    <Typography variant="body2" color="textSecondary">
                      {device.name}
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={onClose}
                      onTouchStart={onClose}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </div>
                  <CardMedia
                    className={classes.media}
                    image={`/api/media/${device.uniqueId}/${deviceImage}`}
                  />
                </>
              ) : (
                <div className={classes.header}>
                  <Typography variant="body2" color="textSecondary">
                    {device.name}
                  </Typography>
                  <IconButton
                    size="small"
                    onClick={onClose}
                    onTouchStart={onClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </div>
              )}
              {position && (
                <CardContent className={classes.content}>
                  <Table size="small" classes={{ root: classes.table }}>
                    <TableBody>
                      {positionItems.split(',').filter((key) => position.hasOwnProperty(key) || position.attributes.hasOwnProperty(key)).map((key) => (
                        <StatusRow
                          key={key}
                          name={positionAttributes.hasOwnProperty(key) ? positionAttributes[key].name : key}
                          content={(
                            <PositionValue
                              position={position}
                              property={position.hasOwnProperty(key) ? key : null}
                              attribute={position.hasOwnProperty(key) ? null : key}
                            />
                          )}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </CardContent>
              )}
              <CardActions classes={{ root: classes.actions }} disableSpacing>
                <IconButton
                  color="secondary"
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  disabled={!position}
                >
                  <PendingIcon />
                </IconButton>
                <IconButton
                  onClick={() => navigate('/replay')}
                  disabled={disableActions || !position}
                >
                  <ReplayIcon />
                </IconButton>
                <IconButton
                  onClick={() => navigate(`/settings/device/${deviceId}/command`)}
                  disabled={disableActions}
                >
                  <PublishIcon />
                </IconButton>
                {/* ICONE DE DESBLOQUEIO */}
                <Tooltip title="Bloquear / Desbloquear Veículo">
                  <IconButton
                    onClick={handleStopResumeEngine}
                    disabled={disableActions || deviceReadonly || deviceInfo?.protocol === 'osmand'
                      || deviceInfo?.protocol === undefined || user?.limitCommands}
                  >
                    {
                      (position?.attributes?.event === 'jt') || (position?.attributes?.blocked === true)
                        || (position?.attributes?.out1 === true)
                        ? (<LockIcon color="error" />)
                        : (<LockOpenIcon color="success" />)
                    }
                  </IconButton>
                </Tooltip>
                {/* ICONE DE ANCORA */}
                {isAnchor && (
                  <Tooltip title="Âncora Ativada">
                    <IconButton
                      onClick={(() => handleDeleteGeofenceAnchor(commandId, notificationId, anchorId))}
                      disabled={disableActions || user?.limitCommands || deviceReadonly}
                    >
                      <AnchorIcon color="error" />
                    </IconButton>
                  </Tooltip>
                )}
                {!isAnchor && (
                  <Tooltip title="Criar Âncora">
                    <IconButton
                      onClick={handleCreateGeofenceAnchor}
                      disabled={disableActions || user?.limitCommands || deviceReadonly}
                    >
                      <AnchorIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <IconButton
                  onClick={() => navigate(`/settings/device/${deviceId}`)}
                  disabled={disableActions || deviceReadonly || !user.administrator}
                >
                  <EditIcon />
                </IconButton>

                <IconButton
                  onClick={() => setRemoving(true)}
                  disabled={disableActions || deviceReadonly || !user.administrator}
                  className={classes.negative}
                >
                  <DeleteIcon />
                </IconButton>
              </CardActions>
            </Card>
          </Draggable>
        )}
      </div>
      {position && (
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
          <MenuItem onClick={() => navigate(`/position/${position.id}`)}><Typography color="secondary">{t('sharedShowDetails')}</Typography></MenuItem>
          <MenuItem onClick={handleGeofence}>{t('sharedCreateGeofence')}</MenuItem>
          <MenuItem component="a" target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${position.latitude}%2C${position.longitude}`}>{t('linkGoogleMaps')}</MenuItem>
          <MenuItem component="a" target="_blank" href={`http://maps.apple.com/?ll=${position.latitude},${position.longitude}`}>{t('linkAppleMaps')}</MenuItem>
          <MenuItem component="a" target="_blank" href={`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${position.latitude}%2C${position.longitude}&heading=${position.course}`}>{t('linkStreetView')}</MenuItem>
        </Menu>
      )}
      <RemoveDialog
        open={removing}
        endpoint="devices"
        itemId={deviceId}
        onResult={(removed) => handleRemove(removed)}
      />
      {/* COMPONENTE PARA DESBLOQUEIO PELO CARD */}
      <StopResumeDialog
        open={stopResume}
        close={() => setStopResume(false)}
        endpoint="devices"
        itemId={deviceId}
        device={device}
        position={position}
        onResult={() => setStopResume(false)}
      />
      {/* COMPONENTE PARA CRIAR ANCORA */}
      <AnchorGeofenceDialog
        open={anchorCustomer}
        close={() => setAnchorCustomer(false)}
        endpoint="devices"
        device={device}
        position={deviceInfo}
        onResult={() => setAnchorCustomer(false)}
      />
      {/* COMPONENTE REMOVER ANCORA */}
      <RemoveAnchorGeofenceDialog
        open={deletAnchorCustomer}
        close={() => setDeleteAnchorCustomer(false)}
        endpoint="devices"
        device={device}
        anchorId={anchorId}
        commandId={commandId}
        notificationId={notificationId}
        position={position}
        onResult={() => setDeleteAnchorCustomer(false)}
      />
      <ToastContainer />
    </>
  );
};

export default StatusCard;
