export default {
  sidebarWidth: 0, /* modificado de 28% para 18% */
  sidebarWidthTablet: '52px',
  drawerWidthDesktop: '428px',
  drawerListDevicesWidthDesktop: '450px', /* Lista de veiculos do modo desktop */
  drawerWidthTablet: '320px',
  drawerHeightPhone: '250px',
  filterFormWidth: '160px',
  eventsDrawerWidth: '320px',
  bottomBarHeight: 56,
  popupMapOffset: 300,
  popupMaxWidth: 288,
  popupImageHeight: 144,
  screenWidth: window.innerWidth,
  scrennHeigth: window.innerHeight,
};
