import {
  amber, grey, green, red, common, blue,
} from '@mui/material/colors';

export default () => {
  /* export default (server) => { */
  const colors = {
    white: common.white,
    background: grey[50],
    primary: '#0F7676',
    secondary: '#0F8282',
    /*  primary: server?.attributes?.colorPrimary || indigo[900],
    secondary: server?.attributes?.colorSecondary || green[800], */
    positive: green[500],
    medium: amber[700],
    negative: red[500],
    neutral: grey[500],
    azul: blue[900],
    geometry: '#3bb2d0',
  };

  return {
    background: {
      default: colors.background,
    },
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
      contrastText: colors.white,
    },
    colors,
  };
};
