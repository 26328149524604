import React from 'react';
// import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
// import { ReactComponent as Logo } from '../resources/images/logo.svg';
import LogoNovo from '../resources/images/logo-1.png';

const useStyles = makeStyles(() => ({
  image: {
    alignSelf: 'center',
    maxWidth: '300px',
    maxHeight: '151px',
    width: 'auto',
    height: 'auto',
    paddingBottom: 10, /* alterado aqui */
  },
}));
// const LogoImage = ({ color }) => {
const LogoImage = () => {
  const classes = useStyles();

  // return <Logo className={classes.image} style={{ color }} />;
  return <img className={classes.image} src={LogoNovo} alt="" />;
};

/* const LogoImage = ({ color }) => {
  const classes = useStyles();

  const imageFile = useSelector((state) => state.session.server.attributes?.logo);

  if (imageFile) {
    return (
      <img className={classes.image} src={imageFile} alt="Logo" />
    );
  }
  return (
    <Logo className={classes.image} style={{ color }} />
  );
}; */

export default LogoImage;
