import { Paper, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import backgroundImage from '../resources/images/bg-medium.jpeg';
import LogoImage from './LogoImage';
import WhatsAppButtomLogin from '../common/components/WhatsAppButtomLogin';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  sidebar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.primary.main,
    paddingBottom: theme.spacing(5),
    width: theme.dimensions.sidebarWidth,
    [theme.breakpoints.down('lg')]: {
      width: theme.dimensions.sidebarWidthTablet,
    },
    /* DEIXA A SIDEBAR COM UMA LINHA VERTICAL */
    [theme.breakpoints.down('sm')]: {
      width: '5%',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    boxShadow: '-2px 0px 16px rgba(0, 0, 0, 0.25)',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'right bottom',
    backgroundRepeat: 'no-repeat',
    background: theme.palette.primary.secondary,
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 0, 0, 0),
    },

  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: theme.spacing(62),
    padding: theme.spacing(5),
    width: '100%',
    backgroundColor: theme.palette.background.default,
    opacity: 0.7,
    [theme.breakpoints.up('lg')]: {
      border: '1px solid',
      borderTopLeftRadius: '15px',
      borderBottomRightRadius: '15px',
      marginTop: '3%',
    },
    [theme.breakpoints.down('lg')]: {
      border: '1px solid',
      borderTopLeftRadius: '15px',
      borderBottomRightRadius: '15px',
      marginTop: '3%',
    },
    [theme.breakpoints.down('sm')]: {
      border: '1px solid',
      borderTopLeftRadius: '15px',
      borderBottomRightRadius: '15px',
      marginTop: '3%',
      maxWidth: '85%',
    },
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <main className={classes.root}>
      <div className={classes.sidebar}>
        {/* REMOVIDO LOGO DA SIDEBAR E ADICONADO NO PAPER */}
        {!useMediaQuery(theme.breakpoints.down('lg'))}
      </div>
      <Paper className={classes.paper}>
        {/* REMOVIDO LOGO DO PAPER E ADICIONADO AO FORM */}
        {!useMediaQuery(theme.breakpoints.down('lg'))}
        <form className={classes.form}>
          {/* alterado aqui, inserido logo */}
          <LogoImage color={theme.palette.primary.main} />
          {children}
        </form>
        <WhatsAppButtomLogin />
      </Paper>
    </main>
  );
};

export default LoginLayout;
